<template>
  <FocusTrap>
    <div class="card">
    <div id="toolbar" class="fixed-table-toolbar">
      <div class="float-left">
        <h2 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> <span class="icon-list"></span>   SalesMan's List</h2>
      </div>
      <div class="float-right search">
        <input  id="txtsearch" class="form-control" type="text" placeholder="Search" autocomplete="off">
      </div>
    </div>

    <div class="table-responsive">
      <table id="salesman-table"
             class="table table-bordered table-columned"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="_id" data-class="d-none">id</th>
          <th data-field="id" data-width="100" >Code</th>
          <th data-field="name" >Name</th>
          <th data-field="add.city" >City</th>
          <th data-field="add.state_id" data-formatter="stateFormatter" >State</th>
          <th data-field="add.mobile" >Mobile</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"> <i class="icon-blocked text-danger" ></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->

    <vmodal name="salesman-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="75%" :height="425" @before-open="beforeOpen" @before-close="beforeClose" >
      <SalesManForm v-bind:mysalesman="salesman" v-on:salesman_saved="loadData" v-on:salesman_window_closed="closeModal" ></SalesManForm>
    </vmodal>

  </div>
  </FocusTrap>
</template>

<script>
import SalesManForm from '@/views/xpos/salesman/SalesManView.vue'
import { userService } from '@/store/auth-header.js'
export default {
  name: 'SalesManView',
  components: {
    SalesManForm
  },
  data () {
    return {
      mytable: {},
      salesman: JSON.parse('{"id":0,"status":0,"type":0,"name":"","alias":"","open_bal":0,"gstin":"","add":{"name":"","street":"","city":"","pin":"","state_id":0,"email":"","tel":"","mobile":""}}')
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    this.$data.mytable = $('#salesman-table');

    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    this.$data.mytable.contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyDocument(id);
        } else if ($(e.target).text() === 'Remove') {
          self.removeDocument(id);
        }
      }
    })

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $('#salesman-table>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();
    })

    self.loadData()
  },
  methods: {
    closeModal () {
      this.$modal.hide('salesman-window');
    },
    showModal () {
      this.$modal.show('salesman-window');
    },
    beforeOpen () {
      console.log('beforeOpen')
    },
    beforeClose () {
      // this.loadData()
      // this.salesman = JSON.parse('{"id":0,"status":0,"type":0,"name":"","alias":"","open_bal":0,"gstin":"","add":{"name":"","street":"","city":"","pin":"","state_id":0,"email":"","tel":"","mobile":""}}')
    },
    loadData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }


      self.$modal.hide('salesman-window');
      self.$data.mytable.bootstrapTable('load', []);

      fetch(`${process.env.VUE_APP_ROOT_API}api/v1/xbunk/salesman/`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.accepted) {
          if (!_.isNull(resp.data)) {
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      })
    },
    modifyDocument (id) {
      let self = this

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.salesman = JSON.parse('{"id":0,"status":0,"type":0,"name":"","alias":"","open_bal":0,"gstin":"","add":{"name":"","street":"","city":"","pin":"","state_id":0,"email":"","tel":"","mobile":""}}');


      fetch(`${process.env.VUE_APP_ROOT_API}api/v1/xbunk/salesman/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.accepted) {
          self.$data.salesman = resp.data;
          self.$modal.show('salesman-window')
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      })
    },
    removeDocument (id) {
      let self = this

      self.$data.salesman._id = id

      const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.salesman)
      }

      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/v1/xbunk/salesman/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('salesman_window');
            self.loadData();
            swal(
              'Deleted!',
              'Your request has been processed',
              'success'
            )
          }).catch(function (err) {
            swal({ title: 'Oops', text: err.toString(), type: 'error' })
          })
        }
      })
    },
  }
}
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
